import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { SERVICE_NS } from "~/constants/i18n";

export const useLocale = (): boolean => {
  const { i18n } = useTranslation();
  const [loading, setLoading] = useState<boolean>(true);
  useEffect(() => {
    const loadLocale = async (): Promise<void> => {
      try {
        await i18n.loadNamespaces(SERVICE_NS);
      } catch (error) {
        console.log(error); //eslint-disable-line no-console
      } finally {
        setLoading(false);
      }
    };

    loadLocale();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return loading;
};
