import React, { type FC } from "react";

import { Spinner } from "@shopify/polaris";

import styles from "./SSpinnerStyles.module.css";

const SSpinner: FC = () => {
  return (
    <div className={styles.mainContainer}>
      <Spinner size="large" />
    </div>
  );
};

export default React.memo(SSpinner);
